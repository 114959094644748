import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


// 画像ファイルパスをプロパティに取るようなコンポーネントを定義
export default ({ fileName, className }) => (

  // ページじゃないコンポーネントでもGraphQLが使えるように
  // StaticQueryタグを使う
  <StaticQuery

    // GraphQLのクエリ引数には何も指定しない！
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                   ...GatsbyImageSharpFluid
                } 
              }
            }
          }
        }
      }
    `}

              /* 
              childImageSharp {
                sizes(maxWidth: 1140) {
                  ...GatsbyImageSharpSizes
                }
              }*/



    // 全画像情報がdataに代入されている
    render={(data) => {

      // 指定した画像ファイルパス（コンポーネントのプロパティ）と
      // 一致するgatsby-image用の情報を取得
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(fileName)
      })

      if (!image) return
      
      // Imgタグでgatsby-imageで最適化された画像を表示する
      const imageFluid = image.node.childImageSharp.fluid
      return <Img fluid={imageFluid} className={className} />
      // const imageSizes = image.node.childImageSharp.sizes
      // return <Img sizes={imageSizes} />
    }}
  />
)